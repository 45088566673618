<template>
  <div>
    <section>
      <Hero>
        <div class="container">
          <HeadText v-if="isMeinHandel" :titletext="'Deutsche Zweitmarkt AG'" :subtext="'Ihr Handelsplatz für geschlossene Fondsbeteiligungen'"></HeadText>
          <HeadText v-else :titletext="'Mein Zweitmarkt'" :subtext="'Ihr Handelsplatz für geschlossene Fondsbeteiligungen'"></HeadText>
        </div>
        <div class="container bg-light">
          <FondslistTableMobile v-if="mobileViewport"></FondslistTableMobile>
          <FondslistTable v-else></FondslistTable>
        </div>
      </Hero>
    </section>
  </div>
</template>

<script>
import Hero from "@/components/HeroPicture";
import HeadText from "@/components/HeadText";
import FondslistTableMobile from "@/components/fondslist/FondslistTableMobile";
import FondslistTable from "@/components/fondslist/FondslistTable";

export default {
  name: "Fondslist",
  components: {FondslistTable, FondslistTableMobile, Hero, HeadText},
  metaInfo() {
    let rel = '';
    let currentUrl = '';
    if(this.client !== 'meinzweitmarkt') {
      rel = 'canonical';
      currentUrl = window.location.href.replace(this.client, 'meinzweitmarkt');
    }
    return {
      title: this.getClientConfig('name') + ' | ' + this.getClientConfig('title'),
      meta: [
        {
          name: 'description',
          content: 'Der Marktplatz für geschlossene Fonds. Wir bieten freien und institutionellen Vertriebsgesellschaften das führende Service- und Plattformangebot für die Auswahl, den Vertrieb, die Abwicklung und die Bestandsverwaltung geschlossener Fonds in Deutschland und Österreich.',
        },
      ],
      link: [
        {
          rel: rel,
          href: currentUrl,
        }
      ]
    }
  },
  computed: {
    client() {
      return this.$store.getters['clientConfig/client']
    },
    isMeinHandel() {
      return this.$store.getters["getSubsite"] === 'meinhandel';
    },
    mobileViewport() {
      return window.innerWidth < this.$store.getters.getMobileBreakpoint;
    }
  },
  methods: {
    getClientConfig(path) {
      return this.$store.getters['clientConfig/config'](path)
    }
  }
}
</script>

<style scoped>

</style>