<template>
  <div>
    
    <b-modal id="addWatchConfirm" 
             title="Beobachtung hinzugefügt"
             header-bg-variant="dark"
             :hide-footer="isWatchLoading">
      <template #modal-footer="{ ok }">
        <b-button variant="primary" @click="ok()" class="btn btn-default btn-form">OK</b-button>
      </template>
      <div class="text-center my-2" v-if="isWatchLoading">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-2">Die Beobachtung wird gespeichert...</strong>
      </div>
      <div v-else>
        <p class="my-4" v-if="hasWatchError">Beim Speichern ist ein Fehler aufgetreten.</p>
        <div class="my-4" v-else>
          <p>Sie beobachten nun <strong>{{ actionedFondsName }}</strong> und erhalten E-Mails mit aktuellen Handelsinformationen.</p>
          <p>Sie können nun auch die Fondsliste nach Ihren beobachteten Fonds filtern.</p>
        </div>
      </div>
    </b-modal>
    
    <b-modal id="deleteWatchConfirm"
             title="Beobachtung entfernt"
             header-bg-variant="dark"
             :hide-footer="isWatchLoading">
      <template #modal-footer="{ ok }"> 
        <b-button variant="primary" @click="ok()" class="btn btn-default btn-form">OK</b-button>
      </template>
      <div class="text-center my-2" v-if="isWatchLoading">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-2">Die Beobachtung wird gelöscht...</strong>
      </div>
      <div v-else>
        <p class="my-4" v-if="hasWatchError">Beim Speichern ist ein Fehler aufgetreten.</p>
        <p class="my-4" v-else>
          Sie beobachten <strong>{{ actionedFondsName }}</strong> nun nicht mehr und erhalten keine E-Mails mit Handelsinformationen.
        </p>
      </div>
    </b-modal>
    
    <b-form @submit="onSubmit">
      <b-row>
        <b-container fluid id="mobileSearch">
          <b-row class="bg-white">
            <b-col class="pl-0 p-2">
              <div class="form-group">
                  <input class="form-control w-100"
                      id="searchcombinedname"
                      type="text"
                      v-model="form.searchcombinedname"
                      placeholder="Wonach suchen Sie?">
              </div>
            </b-col>
            <b-col class="text-right p-2 col-4">
              <div>
                <b-button
                    variant="primary"
                    class="btn btn-default btn-form"
                    type="submit">Suchen</b-button>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="p-0">
              <b-container fluid v-show="anyFilter">
                <b-row class="badge-row">
                  <b-col class="text-left pl-0 pr-0">
                    <b-badge variant="dark" class="mx-1 mt-3" v-if="form.searchcombinedname">
                      {{form.searchcombinedname}} <a class="badge-cancel ml-2" @click="resetFilter('searchcombinedname')"><i class="fas fa-times"></i></a>
                    </b-badge>
                    <b-badge variant="dark" class="mx-1 mt-3" v-if="form.anbieterid">
                      {{mapAnbieterId()}} <a class="badge-cancel ml-2" @click="resetFilter('anbieterid')"><i class="fas fa-times"></i></a>
                    </b-badge>
                    <b-badge variant="dark" class="mx-1 mt-3" v-if="form.assetklasseid">
                      {{mapAssetklasseId()}} <a class="badge-cancel ml-2" @click="resetFilter('assetklasseid')"><i class="fas fa-times"></i></a>
                    </b-badge>
                    <b-badge variant="dark" class="mx-1 mt-3" v-if="form.auftragtype">
                      {{mapAuftragtype()}} <a class="badge-cancel ml-2" @click="resetFilter('auftragtype')"><i class="fas fa-times"></i></a>
                    </b-badge>
                    <b-badge variant="dark" class="mx-1 mt-3" v-if="form.currency">
                      {{form.currency}} <a class="badge-cancel ml-2" @click="resetFilter('currency')"><i class="fas fa-times"></i></a>
                    </b-badge>
                    <b-badge variant="dark" class="mx-1 mt-3" v-if="form.onlyWatched">
                      <i class="fa fa-eye fa-lg" aria-hidden="true" :title="beobachtenWord"></i> <a class="badge-cancel ml-2" @click="resetFilter('onlyWatched')"><i class="fas fa-times"></i></a>
                    </b-badge>
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>

        </b-container>
      </b-row>
    </b-form>
    
    <div class="row py-3">
      <div class="col-sm-12">
        <div v-if="error">
          <div class="alert alert-danger" role="alert">
            <p>Es ist ein Fehler aufgetreten.</p>
            <p class="fs-7">{{ error }}</p>
          </div>
        </div>

        <b-col class="text-left pl-0 pr-0">

          <b-collapse v-model="sortFilterBar">
            <b-button class="sort-tile mb-3" @click="toggleSortLayer">
              <i class="fa fa-sort-alt"></i> Sortieren<br />
              <span class="currentSort">
                {{ getSortObject( form.sortproperty ).display }}
                <i class="fas fa-long-arrow-alt-down" v-if="currentSortDirIsDesc()"></i>
                <i class="fas fa-long-arrow-alt-up" v-else></i>
                <!-- a class="ml-2" @click="resetSort()"><i class="fas fa-times"></i></a -->
              </span>
            </b-button>

            <b-button class="sort-tile mb-3" @click="toggleFilterLayer">
              <i class="fa fa-filter"></i> Filter<br />
              <span class="currentSort">({{ totalfundscount }} Ergebnisse)</span>
            </b-button>
          </b-collapse>

          <b-collapse v-model="filterToggle" id="extended-search" class="pt-3 pl-0 pr-0">
            <b-row class="ml-0 mr-0">
              <b-col md="3" class="mb-2 pl-0">
                <div class="form-group w-100">
                  <b-form-select
                      size="sm"
                      class="w-100"
                      value-field="id"
                      text-field="name"
                      v-model="form.anbieterid"
                      :options="anbieteroptions">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Anbieter --</b-form-select-option>
                    </template>
                  </b-form-select>
                </div>
              </b-col>
              <b-col md="2" class="mb-2 pl-0">
                <div class="form-group w-100">
                  <b-form-select
                      size="sm"
                      class="w-100"
                      value-field="id"
                      text-field="name"
                      v-model="form.assetklasseid"
                      :options="assetklasseoptions">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Assetklasse --</b-form-select-option>
                    </template>
                  </b-form-select>
                </div>
              </b-col>
              <b-col md="2" class="mb-2 pl-0">
                <div class="form-group w-100">
                  <b-form-select
                      size="sm"
                      class="w-100"
                      v-model="form.auftragtype"
                      :options="auftragtypeoptions">
                    <template #first>
                      <b-form-select-option :value="null">-- Alle --</b-form-select-option>
                    </template>
                  </b-form-select>
                </div>
              </b-col>
              <b-col md="2" class="mb-1 pl-0">
                <div class="form-group w-100">
                  <b-form-select
                      size="sm"
                      class="w-100"
                      value-field="isocode"
                      text-field="isocode"
                      v-model="form.currency"
                      :options="currencyoptions">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Währung --</b-form-select-option>
                    </template>
                  </b-form-select>
                </div>
              </b-col>
              <b-col md="3" class="mb-2 pl-0">
                <div class="form-group w-100">
                  <b-form-checkbox
                      v-model="form.onlyWatched"
                      :disabled="!hasToken()"
                      id="onlyWatchedCB"
                      name="onlyWatchedCB"
                  >
                    <span v-if="form.onlyWatched" title="Hierfür müssen Sie sich anmelden">Nur beobachtete Fonds</span>
                    <span v-else>Nur beobachtete Fonds</span>
                  </b-form-checkbox>
                </div>
              </b-col>
            </b-row>
          </b-collapse>
        </b-col>

        <b-collapse id="mobile-sort" class="pt-3" v-model="sortToggle">
          <b-row>
            <b-col class="text-left">
              <ul>
                <li v-on:click="onSort('fondsname');toggleSortFilterBar()">
                  <i class="fa fa-sort-alt"></i> Fonds
                </li>
                <li v-on:click="onSort('anbietername');toggleSortFilterBar()">
                  <i class="fa fa-sort-alt"></i> Anbieter
                </li>
                <li v-on:click="onSort('lastkurs');toggleSortFilterBar()">
                  <i class="fa fa-sort-alt"></i> Letzter Kurs
                </li>
                <li v-on:click="onSort('lastkursdate');toggleSortFilterBar()">
                  <i class="fa fa-sort-alt"></i> Datum
                </li>
                <li v-on:click="onSort('buyrate');toggleSortFilterBar()">
                  <i class="fa fa-sort-alt"></i> Kauf
                </li>
                <li v-on:click="onSort('buyvolume');toggleSortFilterBar()">
                  <i class="fa fa-sort-alt"></i> Volumen (Kauf)
                </li>
                <li v-on:click="onSort('sellrate');toggleSortFilterBar()">
                  <i class="fa fa-sort-alt"></i> Verkauf
                </li>
                <li v-on:click="onSort('sellvolume');toggleSortFilterBar()">
                  <i class="fa fa-sort-alt"></i> Volumen (Verkauf)
                </li>
                <li v-on:click="onSort('totalrevenue');toggleSortFilterBar()">
                  <i class="fa fa-sort-alt"></i> Umsatz
                </li>
              </ul>
            </b-col>
          </b-row>
        </b-collapse>

        <b-table id="fondslistetable" ref="fondslistetable"
                 class="mobileTable"
                 bordered
                 stacked
                 responsive="xl"
                 :isBusy.sync="isBusy"
                 :items="fundsProvider"
                 :fields="fields"
                 :per-page="perPage"
                 :current-page="currentPage"
                 :key="submitCount"
                 :show-header="true"
                 head-variant="dark"
        >

          <template #cell(issuerandfonds)="data">
            <table class="innerTable">
              <tr>
                <td class="text-left label1">
                  Fonds<br /><div>Anbieter</div>
                </td>
                <td class="label2">
                  <strong><router-link :to="detailsUrl(`${data.item.id}`)">{{ fondsNameWithZusatz( data.item ) }}</router-link></strong><br />
                  <span>{{ data.item.anbietername }}</span>
                </td>
              </tr>
            </table>
          </template>

          <template #cell(lastkursanddate)="data">
            <table class="innerTable">
              <tr>
                <td class="text-left label1">
                  Kurs<br /><div>Datum</div>
                </td>
                <td class="label2">
                  {{ formatPercent( data.item.lastkurs ) }}<span v-if="data.item.lastkurs">%</span><span v-else>&nbsp;</span>
                  <br />
                  <span class="fs-7">{{ data.item.lastkursdate }}</span>
                </td>
              </tr>
            </table>
          </template>

          <template #cell(buyrateandvolume)="data">
            <div v-if="data.item.handelausgesetzt">
              <div class="text-left no-fonds">Dieser Fonds ist zurzeit vom Handel ausgesetzt</div>
            </div>
            <div v-else>
              <table class="innerTable">
                <tr>
                  <td class="text-left label1">
                    Kauf<br /><div>Volumen</div>
                  </td>
                  <td class="label2">
                    {{ formatPercent( data.item.buyrate ) }}<span v-if="data.item.buyrate">%</span><span v-else>&nbsp;</span>
                    <br />
                    <span class="fs-7" v-if="data.item.buyvolume">{{data.item.currency}} {{ formatPrice( data.item.buyvolume ) }}</span>
                  </td>
                </tr>
              </table>
            </div>
          </template>

          <template #cell(sellrateandvolume)="data">
            <div v-if="data.item.handelausgesetzt">
              <div class="text-left no-fonds">Dieser Fonds ist zurzeit vom Handel ausgesetzt</div>
            </div>
            <div v-else>
              <table class="innerTable">
                <tr>
                  <td class="text-left label1">
                    Verkauf<br /><div>Volumen</div>
                  </td>
                  <td class="label2">
                    {{ formatPercent( data.item.sellrate ) }}<span v-if="data.item.sellrate">%</span><span v-else>&nbsp;</span>
                    <br />
                    <span class="fs-7" v-if="data.item.sellvolume">{{data.item.currency}} {{ formatPrice( data.item.sellvolume ) }}</span>
                  </td>
                </tr>
              </table>
            </div>
          </template>

          <template #cell(totalrevenueandspan)="data">
            <table class="innerTable">
              <tr>
                <td class="text-left label1">
                  Umsatz<br /><div>Hoch / Tief 12 Monate</div>
                </td>
                <td class="label2">
                  <span v-if="data.item.totalrevenue">{{data.item.currency}} {{ formatPrice( data.item.totalrevenue ) }}</span>
                  <br />
                  <span class="fs-7" v-if="data.item.highrate">{{ formatPercent( data.item.highrate ) }}% / {{ formatPercent( data.item.lowrate ) }}%</span>
                </td>
              </tr>
            </table>
          </template>

          <template #cell(action)="data">
            <table class="innerTable">
              <tr>
                <td class="text-left label1">
                  Aktionen
                </td>
                <td class="label2">
                  <div class="btn-group" role="group" aria-label="Aktionen">
                    <div v-if="!data.item.handelausgesetzt">
                      <a @click="wizardLink(`${data.item.id}`, 'VERKAUF')" class="btn-trade pointer px-2 btn-sell"><span v-if="!isMeinHandel">V</span></a>
                      <a @click="wizardLink(`${data.item.id}`, 'KAUF')" class="btn-trade pointer px-2 btn-buy"><span v-if="!isMeinHandel">K</span></a>
                    </div>

                    <a v-if="hasToken() && !isTrancheWatched( `${data.item.id}` )"
                       @click="watchTranche( `${data.item.id}`, `${ getFondsName( data.item.anbietername, data.item.fondsname, data.item.fondsnamezusatz ) }` )" class="pt-2 pl-1 watchLink">
                      <i class="fa fa-eye fa-lg" aria-hidden="true" :title="beobachtenWord"></i>
                    </a>
                    <a v-if="hasToken() && isTrancheWatched( `${data.item.id}` )"
                       @click="unwatchTranche( `${data.item.id}`, `${ getFondsName( data.item.anbietername, data.item.fondsname, data.item.fondsnamezusatz ) }` )" class="pt-2 pl-1 watchLink">
                      <i class="fas fa-eye-slash fa-lg" aria-hidden="true" :title="beobachtenDeleteWord"></i>
                    </a>
                  </div>
                </td>
              </tr>
            </table>
          </template>

          <template #cell(empty)="">
            <table class="innerTable">
              <tr>
                <td>&nbsp;</td>
              </tr>
            </table>
          </template>


          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Die Daten werden geladen...</strong>
            </div>
          </template>

        </b-table>

        <b-pagination
            id="pagination"
            v-model="currentPage"
            :total-rows="totalfundscount"
            :per-page="perPage"
            aria-controls="fondslistetable"
            align="right"
            v-if="totalfundscount > perPage"
        ></b-pagination>
        
        <div class="text-right fs-7">{{ totalfundscount }} Fonds gefunden</div>
      </div>
    </div>
    
  </div>
</template>

<script>
import priceMixin from "@/mixins/formatPrice";
import percentMixin from "@/mixins/formatPercent";
import axios from "axios";
import router from "../../router";

export default {
  name: "FondslistTable",
  components: {},
  data: () => ({
    error: null,
    actionedFondsName: null,
    isBusy: false,
    currentPage: 1,
    perPage: 25,
    totalfundscount: 0,
    submitCount: 0,
    anbieteroptions: [],
    assetklasseoptions: [],
    auftragtypeoptions: [
      { value: 'KAUF', text: 'Aktuelle Kaufaufträge' },
      { value: 'VERKAUF', text: 'Aktuelle Verkaufaufträge' },
    ],
    currencyoptions: [],
    form: {
      searchcombinedname: '',
      anbieterid: null,
      assetklasseid: null,
      auftragtype: null,
      currency: null,
      onlyWatched: false,
      sortproperty: 'totalrevenue',
      sortdirection: 'desc',
    },
    sortFilterBar: true,
    sortToggle: false,
    filterToggle: false,
    sortoptions: [
      { key: 'fondsname', name: 'searchFondsName', sortdir: 'none', display: 'Fonds' },
      { key: 'anbietername', name: 'searchEmittentName', sortdir: 'none', display: 'Anbieter' },
      { key: 'lastkurs', name: 'lastKurs', sortdir: 'none', display: 'Kurs' },
      { key: 'lastkursdate', name: 'lastKursDate', sortdir: 'none', display: 'Datum' },
      { key: 'buyrate', name: 'buyrate', sortdir: 'none', display: 'Kauf' },
      { key: 'buyvolume', name: 'buyvolume', sortdir: 'none', display: 'Kaufvolumen' },
      { key: 'sellrate', name: 'sellrate', sortdir: 'none', display: 'Verkauf' },
      { key: 'sellvolume', name: 'sellvolume', sortdir: 'none', display: 'Verkaufvolumen' },        
      { key: 'totalrevenue', name: 'totalrevenue', sortdir: 'desc', display: 'Umsatz' },
    ],
  }),
  computed: {
    client() { return this.$route?.params?.client },
    kaufWord() { return "Kaufen" }, 
    verkaufWord() { return "Verkaufen" }, 
    beobachtenWord() { return "Beobachten" },
    beobachtenDeleteWord() { return "Beobachtung löschen" },
    fields() {
      return [
        { key: 'issuerandfonds', label: 'Anbieter', thClass: ["text-left","fs-7"], tdClass: ["mobileRow"] },
        { key: 'lastkursanddate', label: 'Datum', thClass: ["text-left","fs-7"], tdClass: ["mobileRow"] },
        { key: 'buyrateandvolume', label: 'Volumen', thClass: ["text-left","fs-7"], tdClass: ["mobileRow"] },
        { key: 'sellrateandvolume', label: 'Volumen', thClass: ["text-left","fs-7"], tdClass: ["mobileRow"] },
        { key: 'totalrevenueandspan', label: 'Hoch / Tief 12 Monate', thClass: ["text-left","fs-7"], tdClass: ["mobileRow"] },
        { key: 'action', label: '', tdClass: ["align-content-center", "mobileRow"] },
        { key: 'empty', label: '', tdClass: ["mobileRow"] }
      ] 
    },
    token() {
      return this.$store.getters["auth/getToken"]
    },
    isMeinHandel() {
      return this.client === 'meinhandel';
    },
    isWatchLoading() {
      return this.$store.getters["watches/isWaiting"];
    },
    hasWatchError() {
      var watchStatus = this.$store.getters["watches/watchesStatus"];
      return !(watchStatus === undefined || watchStatus === null || watchStatus === '' || watchStatus === "" )
    },
  },
  methods: {
    resetSort() {
      var sortopt = this.getSortObject( 'totalrevenue' );
      sortopt.sortdir = 'asc'; // Then becomes desc in onSort
      this.onSort( 'totalrevenue' );
    },
    getFondsName( anbietername, fondsname, fondsnamezusatz ) {
      let displayname;
      if ( fondsname.startsWith( anbietername ) ) {
        displayname = fondsname;
      }
      else {
        displayname = anbietername + ' ' + fondsname;
      }
      if ( fondsnamezusatz ) {
        displayname = displayname + ' ' + fondsnamezusatz;
      }
      return displayname;
    },
    sortPropDisplay() {
      for( var sortopt of this.sortoptions ) {
        if( sortopt.sortdir !== 'none' ) {
          return sortopt.display;
        }
      }
      return 'Limit';
    },
    currentSortDirIsDesc() {
      var currentSortDir = 'desc';
      for( var sortopt of this.sortoptions ) {
        if( sortopt.sortdir !== 'none' ) {
          currentSortDir = sortopt.sortdir;
        }
      }
      return currentSortDir === 'desc';
    },
    resetFilter( fieldKey ) {
      if ( 'searchcombinedname' === fieldKey ) {
        this.form.searchcombinedname = null;
      }
      else if ( 'anbieterid' === fieldKey ) {
        this.form.anbieterid = null;
      }
      else if ( 'assetklasseid' === fieldKey ) {
        this.form.assetklasseid = null;
      }
      else if ( 'auftragtype' === fieldKey ) {
        this.form.auftragtype = null;
      }
      else if ( 'currency' === fieldKey ) {
        this.form.currency = null;
      }
      else if ( 'onlyWatched' === fieldKey ) {
        this.form.onlyWatched = false;
      }
      else {
        console.log( 'Unknown form field key ' + fieldKey );
      }
      let event = null;
      this.onSubmit( event );
    },
    mapAnbieterId() {
      if (!this.form.anbieterid) return '';
      for( var opt of this.anbieteroptions ) {
        if (opt.id === this.form.anbieterid ) { return opt.name; }
      }
      return '';
    },
    mapAssetklasseId() {
      if (!this.form.assetklasseid) return '';
      for( var opt of this.assetklasseoptions ) {
        if (opt.id === this.form.assetklasseid ) { return opt.name; }
      }
      return '';
    },
    mapAuftragtype() {
      if (!this.form.auftragtype) return '';
      for( var opt of this.auftragtypeoptions ) {
        if (opt.value === this.form.auftragtype ) { return opt.text; }
      }
      return '';
    },
    anyFilter() {
      return this.form.searchcombinedname || 
          this.form.assetklasseid || 
          this.form.currency || 
          this.form.auftragtype || 
          this.form.anbieterid ||
          this.form.onlyWatched; 
    },
    forceRerender() {
      this.submitCount += 1;
    },
    detailsUrl( tid ) {
      return "fondsdetails/" + tid;
    },
    wizardLink( tid, type) {
      if(this.token) {
        router.push({name: 'AuftragsVerwaltung', params: {client: this.$store.getters['getSubsite']}, query: {auftragType: type, tid: tid}});
      } else {
        const a = 'auftragsverwaltung';
        const b = type;
        const c = tid;
        router.push({name: 'login', params: {client: this.$store.getters['getSubsite']}, query: { a, b, c }});
      }
    },
    fondsNameWithZusatz( dataItem ) {
      if ( dataItem.fondsnamezusatz && dataItem.fondsnamezusatz !== '' ) {
        return dataItem.fondsname + ' - ' + dataItem.fondsnamezusatz; 
      }
      return dataItem.fondsname
    },
    onSubmit( event ) {
      if ( event ) { event.preventDefault() }
      this.currentPage = 1;
      this.forceRerender();
    },
    getSortObject( sortkey ) {
      for( var sortopt of this.sortoptions ) {
        if( sortopt.key === sortkey || sortopt.name === sortkey ) {
          return sortopt;
        }
      }  
      return [];
    },
    onSort: function( sortpropkey ) {
      var sortopt = this.getSortObject( sortpropkey );
      // Get current sortdir and then toggle
      if ( 'none' === sortopt.sortdir || 'desc' === sortopt.sortdir ) {
        sortopt.sortdir = 'asc';
      }
      else if ( 'asc' === sortopt.sortdir ) {
        sortopt.sortdir = 'desc';
      }

      var newSort = sortopt.sortdir;

      // reset all others
      for( var sortopt2 of this.sortoptions ) {
        if( sortopt2.key === sortpropkey ) {
          // do nothing;
        }
        else {
          sortopt2.sortdir = 'none';
        }
      }

      this.form.sortproperty = sortpropkey;
      this.form.sortdirection = newSort;

      let event = null;
      this.onSubmit( event );
    },

    loadAnbieter() {
      return axios.get( this.$store.getters.getAllEnvis.API_PRODUKTE_ZWM_ANBIETER )
          .then(response => {
            Promise.resolve( this.anbieteroptions = response.data.content );
          } )  
          .catch(error => {
            console.log(error);
            return [];
          })          
    },
    loadCurrencies() {
      return axios.get( this.$store.getters.getAllEnvis.API_PRODUKTE_ZWM_CURRENCIES )
          .then(response => {
            Promise.resolve( this.currencyoptions = response.data.content );
          } )
          .catch(error => {
            console.log(error);
            return [];
          })
    },
    loadAssetklassen() {
      return axios.get( this.$store.getters.getAllEnvis.API_PRODUKTE_ZWM_ASSETKLASSEN )
          .then(response => {
            Promise.resolve( this.assetklasseoptions = response.data.content );
          } )
          .catch(error => {
            console.log(error);
            return [];
          })
    },
    hasToken() {
      return this.$store.getters["auth/hasToken"];
    },
    getToken() {
      return this.$store.getters["auth/getToken"];
    },
    watchTranche( trancheId, fondsname ) {
      this.actionedFondsName = fondsname;
      const payload = {
        trancheId: trancheId,
      }
      this.$store.dispatch("watches/addWatch", payload );
      this.$bvModal.show( 'addWatchConfirm' );
    },
    unwatchTranche( trancheId, fondsname ) {
      this.actionedFondsName = fondsname;
      const payload = {
        trancheId: trancheId,
      }
      this.$store.dispatch("watches/removeWatch", payload );
      this.$bvModal.show( 'deleteWatchConfirm' );
    },
    isTrancheWatched( trancheId ) {
      return this.$store.getters["watches/isWatched"](trancheId);
    },
    fetchWatches() {
      if ( this.hasToken() ) {
        this.$store.dispatch('watches/loadWatches')
      }
    },
    /* triggered by click in pagination because currentPage is changed */
    fundsProvider( ctx, callback ) {
      this.isBusy = true;
      var config;
      const reqParams = {
        page: ctx.currentPage - 1,
        size: ctx.perPage,
        subsite: 'meinzweitmarkt',
        namequery: this.form.searchcombinedname,
        issuer: this.form.anbieterid,
        currency: this.form.currency,
        typ: this.form.assetklasseid,
        auftragtyp: this.form.auftragtype,
        watched: this.form.onlyWatched,
        sort: this.form.sortproperty ? this.form.sortproperty + ',' + this.form.sortdirection : '',
      };
      if ( this.hasToken() ) {
        const token = this.getToken();
        config = {
          headers: {
            Authorization: `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'XClacksOverhead': 'GNU Terry Pratchett',
            'X-API-KEY': this.$store.getters.getAllEnvis.API_KEY,
          },
          params: reqParams
        }
      }
      else {
        config = {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'XClacksOverhead': 'GNU Terry Pratchett',
            'X-API-KEY': this.$store.getters.getAllEnvis.API_KEY,
          },
          params: reqParams
        }
      }
      this.errorcode = null;
      this.error = null;
      axios.get(this.$store.getters.getAllEnvis.API_PRODUKTE_ZWM, config)
          .then(response => {
            const items = response.data.content;
            this.isBusy = false;
            this.totalfundscount = response.data.page.total_elements;
            callback( items );
          }, error => {
            this.error = error;
            callback( [] );
          } )
          .catch(error => {
            console.log(error);
            this.isBusy = false;
            this.error = error;
            callback( [] );
          })
      
    },
    toggleSortFilterBar() {
      if(!this.sortFilterBar) {
        this.sortFilterBar = true;
      }

      if(this.sortToggle) {
        this.sortToggle = false;
      }
    },
    toggleSortLayer() {
      if(this.sortToggle) {
        this.sortToggle = false;
        return;
      }

      if(this.filterToggle) {
        this.filterToggle = false;
      }

      if(this.sortFilterBar) {
        this.sortFilterBar = false;
      }

      this.sortToggle = true;
    },
    toggleFilterLayer() {
      if(this.filterToggle) {
        this.filterToggle = false;
        return;
      }

      if(this.sortToggle) {
        this.sortToggle = false;
      }

      this.filterToggle = true;
    },
  },
  mixins: [priceMixin,percentMixin],
  created() {
    this.loadCurrencies();
    this.loadAnbieter();
    this.loadAssetklassen();
    this.fetchWatches();
  },
  mounted() {
    
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if ( "deleteWatchConfirm" === modalId || "addWatchConfirm" === modalId ) {
        this.forceRerender();
      }
    })
   
  }
  // Reminder - eventlisteners must be deleted in beforeDestroy if created before
}
</script>

<style lang="scss">

</style>