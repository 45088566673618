<template>
  <div>

    <b-modal id="addWatchConfirm" 
             title="Beobachtung hinzugefügt"
             header-bg-variant="dark"
             :hide-footer="isWatchLoading">
      <template #modal-footer="{ ok }">
        <b-button variant="primary" @click="ok()" class="btn btn-default btn-form">OK</b-button>
      </template>
      <div class="text-center my-2" v-if="isWatchLoading">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-2">Die Beobachtung wird gespeichert...</strong>
      </div>
      <div v-else>
        <p class="my-4" v-if="hasWatchError">Beim Speichern ist ein Fehler aufgetreten.</p>
        <div class="my-4" v-else>
          <p>Sie beobachten nun <strong>{{ actionedFondsName }}</strong> und erhalten E-Mails mit aktuellen Handelsinformationen.</p>
          <p>Sie können nun auch die Fondsliste nach Ihren beobachteten Fonds filtern.</p>
        </div>
      </div>
    </b-modal>
    
    <b-modal id="deleteWatchConfirm"
             title="Beobachtung entfernt"
             header-bg-variant="dark"
             :hide-footer="isWatchLoading">
      <template #modal-footer="{ ok }"> 
        <b-button variant="primary" @click="ok()" class="btn btn-default btn-form">OK</b-button>
      </template>
      <div class="text-center my-2" v-if="isWatchLoading">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-2">Die Beobachtung wird gelöscht...</strong>
      </div>
      <div v-else>
        <p class="my-4" v-if="hasWatchError">Beim Speichern ist ein Fehler aufgetreten.</p>
        <p class="my-4" v-else>
          Sie beobachten <strong>{{ actionedFondsName }}</strong> nun nicht mehr und erhalten keine E-Mails mit Handelsinformationen.
        </p>
      </div>
    </b-modal>
    
    <b-form class="pt-3" @submit="onSubmit" inline>
      <b-container fluid>
        <b-row class="my-1">
          <b-col md="8" class="pl-0 pr-mobile-0">
            <div class="form-group">
                <input class="form-control w-100"
                    id="searchcombinedname" 
                    type="text" 
                    v-model="form.searchcombinedname" 
                    placeholder="Wonach suchen Sie?">
            </div>
          </b-col>
          <b-col md="2" class="text-left col-xs-6 col-sm-6">
            <div class="form-group">
              <b-button v-b-toggle:extended-search>
                Filter&#160;
                <span class="when-open"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                <span class="when-closed"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
              </b-button>
            </div>
          </b-col>
          <b-col md="2" class="text-right pr-0 col-xs-6 col-sm-6">
            <div>
                <b-button 
                    variant="primary" 
                    class="btn btn-default btn-form mb-2" 
                    type="submit">Suchen</b-button>
            </div>
          </b-col>
        </b-row>

        <b-collapse id="extended-search" class="pt-3">
          <b-row>
            <b-col md="3">
              <div class="form-group w-100">
                <b-form-select 
                    size="sm" 
                    class="w-100"
                    value-field="id"
                    text-field="name"
                    v-model="form.anbieterid" 
                    :options="anbieteroptions">
                  <template #first>
                    <b-form-select-option :value="null" disabled>-- Anbieter --</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
            </b-col>
            <b-col md="2">
              <div class="form-group w-100">
                <b-form-select 
                    size="sm" 
                    class="w-100"
                    value-field="id"
                    text-field="name"
                    v-model="form.assetklasseid" 
                    :options="assetklasseoptions">
                  <template #first>
                    <b-form-select-option :value="null" disabled>-- Assetklasse --</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
            </b-col>
            <b-col md="2">
              <div class="form-group w-100">
                <b-form-select 
                    size="sm" 
                    class="w-100" 
                    v-model="form.auftragtype" 
                    :options="auftragtypeoptions">
                  <template #first>
                    <b-form-select-option :value="null">-- Alle --</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
            </b-col>
            <b-col md="2">
              <div class="form-group w-100">
                <b-form-select 
                    size="sm" 
                    class="w-100"
                    value-field="isocode"
                    text-field="isocode"
                    v-model="form.currency" 
                    :options="currencyoptions">
                  <template #first>
                    <b-form-select-option :value="null" disabled>-- Währung --</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
            </b-col>
            <b-col md="3">
              <div class="form-group w-100">
                <b-form-checkbox
                    v-model="form.onlyWatched"
                    :disabled="!hasToken()"
                    id="onlyWatchedCB"
                    name="onlyWatchedCB"
                >
                  <span title="Hierfür müssen Sie sich anmelden">Nur beobachtete Fonds</span>
                </b-form-checkbox>
              </div>
            </b-col>
          </b-row>  
        </b-collapse>
        
      </b-container>
    </b-form>

    <b-container fluid v-show="anyFilter">
      <b-row class="pt-1 badge-row">
        <b-col md="12" class="text-left">
          <b-badge variant="dark" class="mx-1" v-if="form.searchcombinedname">
            {{form.searchcombinedname}} <a class="badge-cancel ml-2" @click="resetFilter('searchcombinedname')"><i class="fas fa-times"></i></a>
          </b-badge>
          <b-badge variant="dark" class="mx-1" v-if="form.anbieterid">
            {{mapAnbieterId()}} <a class="badge-cancel ml-2" @click="resetFilter('anbieterid')"><i class="fas fa-times"></i></a>
          </b-badge>
          <b-badge variant="dark" class="mx-1" v-if="form.assetklasseid">
            {{mapAssetklasseId()}} <a class="badge-cancel ml-2" @click="resetFilter('assetklasseid')"><i class="fas fa-times"></i></a>   
          </b-badge>
          <b-badge variant="dark" class="mx-1" v-if="form.auftragtype">
            {{mapAuftragtype()}} <a class="badge-cancel ml-2" @click="resetFilter('auftragtype')"><i class="fas fa-times"></i></a>
          </b-badge>
          <b-badge variant="dark" class="mx-1" v-if="form.currency">
            {{form.currency}} <a class="badge-cancel ml-2" @click="resetFilter('currency')"><i class="fas fa-times"></i></a>
          </b-badge>
          <b-badge variant="dark" class="mx-1" v-if="form.onlyWatched">
            <i class="fa fa-eye fa-lg" aria-hidden="true" :title="beobachtenWord"></i> <a class="badge-cancel ml-2" @click="resetFilter('onlyWatched')"><i class="fas fa-times"></i></a>
          </b-badge>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid v-if="!this.isDefaultSort()">
      <b-row class="pt-1">
        <b-col md="12" class="text-left pl-0">
          <b-badge variant="warning" class="mr-1">
            Sortierung nach {{ getSortObject( form.sortproperty ).displayName }}, {{ form.sortdirection === 'asc' ? 'auf' : 'ab' }}steigend 
            <a class="badge-cancel ml-2" @click="resetSort()"><i class="fas fa-times"></i></a>
          </b-badge>
        </b-col>
      </b-row>
    </b-container>
    
    <div class="row py-3">
      <div class="col-sm-12">
        <div v-if="error">
          <div class="alert alert-danger" role="alert">
            <p>Es ist ein Fehler aufgetreten.</p>
            <p class="fs-7">{{ error }}</p>
          </div>
        </div>
        <b-table id="fondslistetable" ref="fondslistetable"
            bordered 
            striped
            responsive="xl"
            :isBusy.sync="isBusy"     
            :items="fundsProvider"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            :key="submitCount"
            head-variant="dark"
        >

          <template #thead-top="">
            <b-tr variant="dark">
              <b-th style="border-bottom: none" class="col-35-percent">
                <table-header-sort-element 
                    :sortprop="getSortObject( 'fondsname' ).key"
                    :sortdir="getSortObject( 'fondsname' ).sortdir"
                    v-on:sort-clicked="onSort"
                >
                  Fonds
                </table-header-sort-element>
              </b-th>
              <b-th style="border-bottom: none" class="col-10-percent">
                <table-header-sort-element
                    :sortprop="getSortObject( 'lastkurs' ).key"
                    :sortdir="getSortObject( 'lastkurs' ).sortdir"
                    v-on:sort-clicked="onSort"
                >
                  Kurs
                </table-header-sort-element>
              </b-th>  
              <b-th style="border-bottom: none" class="col-10-percent">
                <table-header-sort-element
                    :sortprop="getSortObject( 'buyrate' ).key"
                    :sortdir="getSortObject( 'buyrate' ).sortdir"
                    v-on:sort-clicked="onSort"
                >
                  Kauf
                </table-header-sort-element>
              </b-th>
              <b-th style="border-bottom: none" class="col-10-percent">
                <table-header-sort-element
                    :sortprop="getSortObject( 'sellrate' ).key"
                    :sortdir="getSortObject( 'sellrate' ).sortdir"
                    v-on:sort-clicked="onSort"
                >
                  Verkauf
                </table-header-sort-element>
              </b-th>
              <b-th style="border-bottom: none" class="col-20-percent">
                <table-header-sort-element
                    :sortprop="getSortObject( 'totalrevenue' ).key"
                    :sortdir="getSortObject( 'totalrevenue' ).sortdir"
                    v-on:sort-clicked="onSort"
                >
                  Umsatz
                </table-header-sort-element>
              </b-th>
              <b-th style="border-bottom: none" class="col-15-percent">
                Aktionen
              </b-th>
            </b-tr>
          </template>
          
          <template #head(issuerandfonds)="">
            <table-header-sort-element
                :sortprop="getSortObject( 'anbietername' ).key"
                :sortdir="getSortObject( 'anbietername' ).sortdir"
                v-on:sort-clicked="onSort"
            >
              Anbieter
            </table-header-sort-element>  
          </template>

          <template #head(lastkursanddate)="">
            <table-header-sort-element
                :sortprop="getSortObject( 'lastkursdate' ).key"
                :sortdir="getSortObject( 'lastkursdate' ).sortdir"
                v-on:sort-clicked="onSort"
            >
              Datum
            </table-header-sort-element>
          </template>

          <template #head(buyrateandvolume)="">
            <table-header-sort-element
                :sortprop="getSortObject( 'buyvolume' ).key"
                :sortdir="getSortObject( 'buyvolume' ).sortdir"
                v-on:sort-clicked="onSort"
            >
              <span style="overflow-wrap: break-word;">Volumen</span>
            </table-header-sort-element>
          </template>

          <template #head(sellrateandvolume)="">
            <table-header-sort-element
                :sortprop="getSortObject( 'sellvolume' ).key"
                :sortdir="getSortObject( 'sellvolume' ).sortdir"
                v-on:sort-clicked="onSort"
            >
              <span style="overflow-wrap: break-word;">Volumen</span>
            </table-header-sort-element>
          </template>
          
          <template #cell(issuerandfonds)="data">
            <div class="text-left"><strong><router-link :to="detailsUrl(`${data.item.id}`)">{{ fondsNameWithZusatz( data.item ) }}</router-link></strong></div>
            <div class="text-left">{{ data.item.anbietername }}</div>
          </template>

          <template #cell(lastkursanddate)="data">
            <div class="text-left">
              {{ formatPercent( data.item.lastkurs ) }}<span v-if="data.item.lastkurs">%</span><span v-else>&nbsp;</span>
            </div>
            <div class="text-left fs-7">{{ data.item.lastkursdate }}</div>
          </template>

          <template #cell(buyrateandvolume)="data">
            <div v-if="data.item.handelausgesetzt">
              <div class="text-left">Dieser Fonds ist zurzeit vom Handel ausgesetzt</div>  
            </div>
            <div v-else>
              <div class="text-left">
                {{ formatPercent( data.item.buyrate ) }}<span v-if="data.item.buyrate">%</span><span v-else>&nbsp;</span>
              </div>
              <div class="text-left fs-7">
                <span v-if="data.item.buyvolume">{{data.item.currency}} {{ formatPrice( data.item.buyvolume ) }}</span>
              </div>  
            </div>
            
          </template>

          <template #cell(sellrateandvolume)="data">
            <div v-if="data.item.handelausgesetzt">
              <div class="text-left">Dieser Fonds ist zurzeit vom Handel ausgesetzt</div>
            </div>
            <div v-else>
              <div class="text-left">
                {{ formatPercent( data.item.sellrate ) }}<span v-if="data.item.sellrate">%</span><span v-else>&nbsp;</span>
              </div>
              <div class="text-left fs-7">
                <span v-if="data.item.sellvolume">{{data.item.currency}} {{ formatPrice( data.item.sellvolume ) }}</span>
              </div>  
            </div>
          </template>

          <template #cell(totalrevenueandspan)="data">
            <div class="text-left text-nowrap">
              <span v-if="data.item.totalrevenue">{{data.item.currency}} {{ formatPrice( data.item.totalrevenue ) }}</span>
            </div>
            <div class="text-left fs-7">
              <span v-if="data.item.highrate">{{ formatPercent( data.item.highrate ) }}% / {{ formatPercent( data.item.lowrate ) }}%</span>
            </div>
          </template>

          <template #cell(action)="data">
            <div class="btn-group" role="group" aria-label="Aktionen">
              <div v-if="!data.item.handelausgesetzt">
                <a @click="wizardLink(`${data.item.id}`, 'VERKAUF')" class="btn-trade pointer px-2 btn-sell"><span v-if="!isMeinHandel">V</span></a>
                <a @click="wizardLink(`${data.item.id}`, 'KAUF')" class="btn-trade pointer px-2 btn-buy"><span v-if="!isMeinHandel">K</span></a>
              </div>
              
              <a v-if="hasToken() && !isTrancheWatched( `${data.item.id}` )" 
                 @click="watchTranche( `${data.item.id}`, `${ getFondsName( data.item.anbietername, data.item.fondsname, data.item.fondsnamezusatz ) }` )" class="pt-2 pl-1 watchLink">
                <i class="fa fa-eye fa-lg" aria-hidden="true" :title="beobachtenWord"></i>
              </a>
              <a v-if="hasToken() && isTrancheWatched( `${data.item.id}` )" 
                 @click="unwatchTranche( `${data.item.id}`, `${ getFondsName( data.item.anbietername, data.item.fondsname, data.item.fondsnamezusatz ) }` )" class="pt-2 pl-1 watchLink">
                <i class="fas fa-eye-slash fa-lg" aria-hidden="true" :title="beobachtenDeleteWord"></i>
              </a>
            </div>
          </template>

          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Die Daten werden geladen...</strong>
            </div>
          </template>
        </b-table>

        <b-pagination
            id="pagination"
            v-model="currentPage"
            :total-rows="totalfundscount"
            :per-page="perPage"
            aria-controls="fondslistetable"
            align="right"
            v-if="totalfundscount > perPage"
        ></b-pagination>

        <div class="text-right fs-7">{{ totalfundscount }} Fonds gefunden</div>
      </div>
    </div>

  </div>
</template>

<script>
import priceMixin from "@/mixins/formatPrice";
import percentMixin from "@/mixins/formatPercent";
import axios from "axios";
import TableHeaderSortElement from "@/components/sorting/TableHeaderSortElement";
import router from "../../router";

export default {
  name: "FondslistTable",
  components: {TableHeaderSortElement},
  data: () => ({
    /* table controls */
    isBusy: false,
    currentPage: 1,
    perPage: 25,
    totalfundscount: 0,
    submitCount: 0,
    
    error: null,
    actionedFondsName: null,
    anbieteroptions: [],
    assetklasseoptions: [],
    auftragtypeoptions: [
      { value: 'KAUF', text: 'Aktuelle Kaufaufträge' },
      { value: 'VERKAUF', text: 'Aktuelle Verkaufaufträge' },
    ],
    currencyoptions: [],
    form: {
      searchcombinedname: '',
      anbieterid: null,
      assetklasseid: null,
      auftragtype: null,
      currency: null,
      onlyWatched: false,
      sortproperty: 'totalrevenue',
      sortdirection: 'desc',
    },
    sortoptions: [
      { key: 'fondsname', name: 'searchFondsName', sortdir: 'none', displayName: 'Fonds' },
      { key: 'anbietername', name: 'searchEmittentName', sortdir: 'none', displayName: 'Anbieter' },
      { key: 'lastkurs', name: 'lastKurs', sortdir: 'none', displayName: 'Kurs' },
      { key: 'lastkursdate', name: 'lastKursDate', sortdir: 'none', displayName: 'Datum' },
      { key: 'buyrate', name: 'buyrate', sortdir: 'none', displayName: 'Kauf' },
      { key: 'buyvolume', name: 'buyvolume', sortdir: 'none', displayName: 'Kaufvolumen' },
      { key: 'sellrate', name: 'sellrate', sortdir: 'none', displayName: 'Verkauf' },
      { key: 'sellvolume', name: 'sellvolume', sortdir: 'none', displayName: 'Verkaufvolumen' },        
      { key: 'totalrevenue', name: 'totalrevenue', sortdir: 'desc', displayName: 'Umsatz' },
    ],
  }),
  computed: {
    kaufWord() { return "Kaufen" }, 
    verkaufWord() { return "Verkaufen" }, 
    beobachtenWord() { return "Beobachten" },
    beobachtenDeleteWord() { return "Beobachtung löschen" },
    fields() {
      return [
        { key: 'issuerandfonds', label: 'Anbieter', thClass: ["text-left","fs-7"], thStyle: { minWidth: "128px"} },
        { key: 'lastkursanddate', label: 'Datum', thClass: ["text-left","fs-7"] },
        { key: 'buyrateandvolume', label: 'Volumen', thClass: ["text-left","fs-7"] },
        { key: 'sellrateandvolume', label: 'Volumen', thClass: ["text-left","fs-7"] },
        { key: 'totalrevenueandspan', label: 'Hoch / Tief 12 Monate', thClass: ["text-left","fs-7"] },
        { key: 'action', label: '', tdClass: ["align-content-center"] },
      ] 
    },
    token() {
      return this.$store.getters["auth/getToken"]
    },
    client() {
      return this.$route?.params?.client
    },
    isMeinHandel() {
      return this.client === 'meinhandel';
    },
    isWatchLoading() {
      return this.$store.getters["watches/isWaiting"];
    },
    hasWatchError() {
      var watchStatus = this.$store.getters["watches/watchesStatus"];
      return !(watchStatus === undefined || watchStatus === null || watchStatus === '' || watchStatus === "" )
    },
  },
  methods: {
    isTrancheWatched( trancheId ) {
      return this.$store.getters["watches/isWatched"](trancheId);
    },
    getFondsName( anbietername, fondsname, fondsnamezusatz ) {
      let displayname;
      if ( fondsname.startsWith( anbietername ) ) {
        displayname = fondsname;
      }
      else {
        displayname = anbietername + ' ' + fondsname;
      }
      if ( fondsnamezusatz ) {
        displayname = displayname + ' ' + fondsnamezusatz;
      }
      return displayname;
    },
    isDefaultSort() {
      return this.form.sortproperty === 'totalrevenue' && this.form.sortdirection === 'desc';  
    },
    resetSort() {
      this.form.sortproperty = 'totalrevenue';
      this.form.sortdirection = 'desc';
      this.onSort( this.form.sortproperty, this.form.sortdirection );
    },
    resetFilter( fieldKey ) {
      if ( 'searchcombinedname' === fieldKey ) {
        this.form.searchcombinedname = null;
      }
      else if ( 'anbieterid' === fieldKey ) {
        this.form.anbieterid = null;
      }
      else if ( 'assetklasseid' === fieldKey ) {
        this.form.assetklasseid = null;
      }
      else if ( 'auftragtype' === fieldKey ) {
        this.form.auftragtype = null;
      }
      else if ( 'currency' === fieldKey ) {
        this.form.currency = null;
      }
      else if ( 'onlyWatched' === fieldKey ) {
        this.form.onlyWatched = false;
      }
      else {
        console.log( 'Unknown form field key ' + fieldKey );
      }
      let event = null;
      this.onSubmit( event );
    },
    mapAnbieterId() {
      if (!this.form.anbieterid) return '';
      for( var opt of this.anbieteroptions ) {
        if (opt.id === this.form.anbieterid ) { return opt.name; }
      }
      return '';
    },
    mapAssetklasseId() {
      if (!this.form.assetklasseid) return '';
      for( var opt of this.assetklasseoptions ) {
        if (opt.id === this.form.assetklasseid ) { return opt.name; }
      }
      return '';
    },
    mapAuftragtype() {
      if (!this.form.auftragtype) return '';
      for( var opt of this.auftragtypeoptions ) {
        if (opt.value === this.form.auftragtype ) { return opt.text; }
      }
      return '';
    },
    anyFilter() {
      return this.form.searchcombinedname || 
          this.form.assetklasseid || 
          this.form.currency || 
          this.form.auftragtype || 
          this.form.anbieterid ||
          this.form.onlyWatched; 
    },
    forceRerender() {
      this.submitCount += 1;
    },
    detailsUrl( tid ) {
      return "fondsdetails/" + tid;
    },
    wizardLink( tid, type) {
      if(this.token) {
        router.push({name: 'AuftragsVerwaltung', params: {client: this.$store.getters['getSubsite']}, query: {auftragType: type, tid: tid}});
      } else {
        const a = 'auftragsverwaltung';
        const b = type;
        const c = tid;
        router.push({name: 'login', params: {client: this.$store.getters['getSubsite']}, query: { a, b, c }});
      }
    },
    fondsNameWithZusatz( dataItem ) {
      if ( dataItem.fondsnamezusatz && dataItem.fondsnamezusatz !== '' ) {
        return dataItem.fondsname + ' - ' + dataItem.fondsnamezusatz; 
      }
      return dataItem.fondsname
    },
    onSubmit( event ) {
      if ( event ) { event.preventDefault() }
      this.currentPage = 1;
      this.forceRerender();
    },
    getSortObject( sortkey ) {
      for( var sortopt of this.sortoptions ) {
        if( sortopt.key === sortkey || sortopt.name === sortkey ) {
          return sortopt;
        }
      }  
      return [];
    },
    onSort: function (sortKey, sortDirection) {
      for (let sortOptionIx in this.sortoptions) {
        this.sortoptions[sortOptionIx].sortdir = "none"
      }

      for (let sortOption of this.sortoptions) {
        if(sortOption.key === sortKey) {
          sortOption.sortdir = sortDirection;

          this.form.sortdirection = sortDirection;
          this.form.sortproperty = sortOption.name;

          break;
        }
      }
      this.onSubmit(null);
    },
    loadAnbieter() {
      return axios.get( this.$store.getters.getAllEnvis.API_PRODUKTE_ZWM_ANBIETER )
          .then(response => {
            Promise.resolve( this.anbieteroptions = response.data.content );
          } )  
          .catch(error => {
            console.log(error);
            return [];
          })          
    },
    loadCurrencies() {
      return axios.get( this.$store.getters.getAllEnvis.API_PRODUKTE_ZWM_CURRENCIES )
          .then(response => {
            Promise.resolve( this.currencyoptions = response.data.content );
          } )
          .catch(error => {
            console.log(error);
            return [];
          })
    },
    loadAssetklassen() {
      return axios.get( this.$store.getters.getAllEnvis.API_PRODUKTE_ZWM_ASSETKLASSEN )
          .then(response => {
            Promise.resolve( this.assetklasseoptions = response.data.content );
          } )
          .catch(error => {
            console.log(error);
            return [];
          })
    },
    hasToken() {
      return this.$store.getters["auth/hasToken"];
    },
    getToken() {
      return this.$store.getters["auth/getToken"];  
    },
    watchTranche( trancheId, fondsname ) {
      this.actionedFondsName = fondsname;
      const payload = {
        trancheId: trancheId,
      }
      this.$store.dispatch("watches/addWatch", payload );
      this.$bvModal.show( 'addWatchConfirm' );
    },
    unwatchTranche( trancheId, fondsname ) {
      this.actionedFondsName = fondsname;
      const payload = {
        trancheId: trancheId,
      }
      this.$store.dispatch("watches/removeWatch", payload );
      this.$bvModal.show( 'deleteWatchConfirm' );
    },
    fetchWatches() {
      if ( this.hasToken() ) {
        this.$store.dispatch('watches/loadWatches')
      }
    },
    /* triggered by click in pagination because currentPage is changed */
    fundsProvider( ctx, callback ) {
      this.isBusy = true;
      var config;
      const reqParams = {
        page: ctx.currentPage - 1,
        size: ctx.perPage,
        subsite: 'meinzweitmarkt',
        namequery: this.form.searchcombinedname,
        issuer: this.form.anbieterid,
        currency: this.form.currency,
        typ: this.form.assetklasseid,
        auftragtyp: this.form.auftragtype,
        watched: this.form.onlyWatched,
        sort: this.form.sortproperty ? this.form.sortproperty + ',' + this.form.sortdirection : '',
      };
      if ( this.hasToken() ) {
        const token = this.getToken();
        config = {
          headers: {
            Authorization: `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'XClacksOverhead': 'GNU Terry Pratchett',
            'X-API-KEY': this.$store.getters.getAllEnvis.API_KEY,
          },
          params: reqParams
        }
      }
      else {
        config = {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'XClacksOverhead': 'GNU Terry Pratchett',
            'X-API-KEY': this.$store.getters.getAllEnvis.API_KEY,
          },
          params: reqParams
        }
      }
      
      this.errorcode = null;
      this.error = null;
      axios.get(this.$store.getters.getAllEnvis.API_PRODUKTE_ZWM, config)
          .then(response => {
            const items = response.data.content;
            this.isBusy = false;
            this.totalfundscount = response.data.page.total_elements;
            callback( items );
          }, error => {
            this.error = error;
            callback( [] );
          } )
          .catch(error => {
            console.log(error);
            this.isBusy = false;
            this.error = error;
            callback( [] );
          })
      
    },
  },
  mixins: [priceMixin,percentMixin],
  created() {
    this.loadCurrencies();
    this.loadAnbieter();
    this.loadAssetklassen();
    this.fetchWatches();
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if ( "deleteWatchConfirm" === modalId || "addWatchConfirm" === modalId ) {
        this.forceRerender();
      }
    })
   
  }
  // Reminder - eventlisteners must be deleted in beforeDestroy if created before
}
</script>

<style>

</style>